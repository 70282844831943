<template>
  <main class="default-index">
    <!--content 部分-->
    <div class="home" >
      <xgj-modal v-model="videoShow" width="800px" :closeModal="true" @close="closeVideoShow">
        <div class="demo">
          <video-player class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="playerOptions">
          </video-player>
        </div>
      </xgj-modal>
      <div class="home-banner">
        <div class="el-banner">
          <div class="swiper-container" id="home-banner">
            <div class="swiper-wrapper">
              <div class="swiper-slide"  v-for="(banner, index) in bannerList" :key="index" :style="{ height: sliderHeight+'px' }">
                <img v-if="banner.image" :src="banner.image.url" />
                <div v-if="banner.attach" class="auto-plpay" :class="[banner.attach ? 'swiper-activer' : '']">
                  <div class="auto-plpay-txt" @click="playVideo(banner.attach)">
                    <span>播放视频</span>
                    <i class="sea-iconfont">&#xe643;</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div><!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
          </div>
        </div>
      </div>

      <div class="home-notice">
        <div class="notice-box">
          <h4 class="home-notice-title">
            最新公告
          </h4>
          <div class="notice-rolling">
            <xgj-notice v-if="options" :options="options"></xgj-notice>
          </div>
        </div>
      </div>

      <div class="home-article-content">
        <div class="article-content-top">
          <div class="article-news">
            <div class="article-title">
              <h4>新闻资讯</h4>
              <span><router-link to="/news/news-list">阅读更多<i></i></router-link></span>
            </div>
            <div class="news-box">
              <div class="news-banner">
                <div class="swiper-container" id="news-banner">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(news, index) in newsList" :key="news.id">
                      <router-link :to="{path: '/news/news-list', query: {id: news.id,listView: false}}">
                        <img class="news-img" v-if="news.image" :src="news.image.url" />
                        <img class="news-img" v-else src="../assets/images/index/new-news-banner.png" />
                      </router-link>
                    </div>
                  </div>
                  <div class="swiper-pagination"></div><!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
                </div>
              </div>
              <div class="news-list">
                <div class="news-item" v-for="(news, index) in newsList" :key="news.id">
                  <router-link :to="{path: '/news/news-list', query: {id: news.id,listView: false}}">
                    <span></span>
                    <div class="ie-mui-ellipsis">{{news.title}}</div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="article-policy">
            <div class="article-title">
              <h4>政策法规</h4>
              <span><router-link to="/policy/policy-list">阅读更多<i></i></router-link></span>
            </div>
            <div class="policy-box">
              <div class="policy-list">
                <div class="policy-item" v-for="(polic, index) in policiesList" :key="polic.id">
                  <router-link :to="{path: '/policy/policy-list', query: {id: polic.id, listView: false}}">
                    <span></span>
                    <div class="ie-mui-ellipsis">{{polic.title}}</div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="article-apply">
            <div class="article-title">
              <h4>海葬线上报名</h4>
              <span><router-link to="/sea/sea-index">阅读更多<i></i></router-link></span>
            </div>
            <div class="apply-box">
              <div class="apply-list">
                <div class="apply-item" @click="anchorTo('dalian')">
                  <img src="../assets/images/index/new-index-apply-dl.png" />
                </div>
                <div class="apply-item" @click="anchorTo('yingkou')">
                  <img src="../assets/images/index/new-index-apply-yk.png" />
                </div>
                <div class="apply-item" @click="anchorTo('dandong')">
                  <img src="../assets/images/index/new-index-apply-dd.png" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="article-content-bot">
          <div class="article-hall">
            <div class="article-title">
              <h4>海葬纪念堂</h4>
              <span @click="hallMore"><router-link to="">阅读更多<i></i></router-link></span>
            </div>
            <div class="hall-box">
              <div class="swiper-container" id="hall-banner" v-if="hallList && hallList.length > 0" >
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(hall, index) in hallList" :key="hall.id">
                    <div class="hall-item" @click="toHall(hall)">
                      <div class="hall-avater">
                        <img v-if="hall.hall" :src="hall.hall.image.url" />
                        <img v-else src="../assets/images/index/new-index-hall-swiper.png" />
                      </div>
                      <div class="hall-content">
                        <div class="hall-name">{{hall.hall.name}}</div>
                        <div class="hall-dead" v-if="hall.dead_list[0].birthday || hall.dead_list[0].dead_day">（{{hall.dead_list[0].birthday}}——{{hall.dead_list[0].dead_day}}）</div>
                        <div class="hall-dead" v-else>暂无时间记录</div>
                        <article v-if="hall.dead_list[0].summary" class="hall-article">
                          {{hall.dead_list[0].summary | fiterIntercept}}
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-pagination"></div><!--分页器。如果放置在swiper-container外面，需要自定义样式。-->
              </div>
              <xgj-empty v-else></xgj-empty>
            </div>
          </div>

          <div class="article-notice">
            <div class="article-title">
              <h4>往期公告</h4>
              <span><router-link :to="{path: '/news/news-list', query: {type: 2}}">阅读更多<i></i></router-link></span>
            </div>
            <div class="notice-box">
              <div class="notice-list">
                <div class="notice-item" v-for="(issue, index) in issueList" :key="issue.id">
                  <router-link :to="{path: '/news/news-list', query: {id: issue.id,listView: false}}">
                    <span></span>
                    <div class="ie-mui-ellipsis">{{issue.title}}</div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="article-people">
            <div class="article-title">
              <h4>员工风采</h4>
              <span><router-link to="/about/presence">查看更多<i></i></router-link></span>
            </div>
            <div class="people-box">
              <div class="people-list clearfix">
                <div class="people-item" v-for="(staff, index) in staffList">
                  <router-link to>
                    <img v-if="staff.content_image_list" :src="staff.content_image_list[1].url" />
                    <img v-else src="../assets/images/deaflut-avater.jpg" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--content 部分-->
  </main>
</template>

<script>
  import 'swiper/dist/css/swiper.css'
  import Swiper from 'swiper/dist/js/swiper.js'
  import util from "../utils/util";

  export default {
    data() {
      return {
        options: '',
        bannerList: [],
        policiesList: [],
        newsList: [],
        staffList: [], //员工列表
        issueList: [],
        hallList: null,
        videoShow: false,
        playerOptions: {
          //播放速度
          playbackRates: [0.5, 1.0, 1.5, 2.0],
          //如果true,浏览器准备好时开始回放。
          autoplay: false,
          // 默认情况下将会消除任何音频。
          muted: false,
          // 导致视频一结束就重新开始。
          loop: false,
          // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          preload: 'auto',
          language: 'zh-CN',
          // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          aspectRatio: '16:9',
          // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          fluid: true,
          sources: [{
            //类型
            type: "video/mp4",
            //url地址
            src: ""
          }],
          //你的封面地址
          poster: '',
          //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          notSupportedMessage: '此视频暂无法播放，请稍后再试',
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            //全屏按钮
            fullscreenToggle: true
          },
        },
        sliderHeight: '', // banner 滑块高度
      }
    },
    created() {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        background: 'rgba(255, 255, 255, 0.8)'
      });
      Promise.all([this.getRecommendColumnName(), this.getRecommendRecomHall(), this.getContentBanner(), this.getContentColumnName(), this.getFuneralStaffShow()])
        .then(() => {
          loading.close()
        }).catch(reason => {
          loading.close()
        })
    },
    mounted() {
      // ==================== 实现头图自适应高度 ====================
      // 初始化滑块高度，自适应浏览器宽度
      this.sliderHeight = window.innerWidth/4 - 15;
      // 监听浏览器宽度改变，调整滑块高度
      window.onresize = ()=>{
        this.sliderHeight = window.innerWidth/4 - 15;
      }
      const that = this;
      that.swiperVideo = new Swiper('#home-banner', {
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
          clickable :true,
        },
        effect : 'fade',
        fadeEffect: {
          crossFade: true,
        },
        allowTouchMove: true,
        parallax: true,
        //清除video遮挡文字BUG
        speed: 1000,
        loopedSlides: 3,
        on: {
          transitionEnd: function() {
            // this.slides.eq(this.previousIndex).find('video')[0].pause()
            // this.slides.eq(this.activeIndex).find('video')[0].play()
          },
          init: function() {
            this.emit('transitionEnd');
          },
        },
        observeParents: false,   //注意这里！！
        observer: true, //也要注意这里！！
      });
      that.swiperNews = new Swiper('#news-banner', {
        autoplay: true,
        effect : 'fade',
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable :true,
        },
        observeParents: false,   //注意这里！！
        observer: true, //也要注意这里！！
      });
    },
    filters: {
      fiterIntercept(value) {
        return `${value.substring(0,100)}${value.length > 100 ? '...' : ''}`
      }
    },
    methods: {
      // 初始化轮播图
      initHallSwiper() {
        let that = this;
        that.swiperHall = new Swiper('#hall-banner', {
          autoplay: true,
          effect : 'flip',
          pagination: {
            el: '.swiper-pagination',
            clickable :true,
          },
          observeParents: false,   //注意这里！！
          observer: true, //也要注意这里！！
        })
      },

      closeVideoShow() {
        this.videoShow = false;
      },
      playVideo(attach) {
        this.playerOptions.sources[0].src = attach.url;
        this.videoShow = true;
      },
      anchorTo(selector) {
        this.$router.push({path: '/sea/sea-index', query: {selector: selector}})
      },
      toHall(hall) {
        let a = document.createElement('a');
        a.href = `${process.env.VUE_APP_WORSHIP_HOST}${process.env.VUE_APP_WORSHIP_PAGE}/hall/palace-hall?hallId=${hall.hall.id}`;
        a.target = '_blank';
        a.click()
      },
      hallMore() {
        let a = document.createElement('a');
        a.href = `${process.env.VUE_APP_WORSHIP_HOST}${process.env.VUE_APP_WORSHIP_PAGE}/hall/hall-list?path=celebrity`;
        a.target = '_blank';
        a.click()
      },
      getContentBanner() {
        const that = this;
        return new Promise((resolve, reject) => {
          let params = {
            partnerId: that.$store.state.user.partnerId,
            banner: '首页轮播',
          };
          that.$api.website.getContentBanner(params)
            .then(datas => {
              if (datas.length > 0) {
                datas.forEach( data => {
                  that.bannerList.push(data)
                })
              }
              resolve()
            }).catch( err => {
              reject(err)
          })
        })
      },
      getFuneralStaffShow() {
        const that = this;
        return new Promise((resolve, reject) => {
          let paramsPeople = {
            partnerId: that.$store.state.user.partnerId,
            name: '员工风采',
            page: 1,
            rows: 6
          };
          that.$api.website.getFuneralStaffShow(paramsPeople)
            .then( datas => {
              if (datas.length > 0) {
                datas.forEach( data => {
                  let list = data.website_content;
                  that.staffList.push(list)
                });
              }
              resolve()
            }).catch( err => {
             reject(err)
          });
        })
      },
      getContentColumnName() {
        const that = this;
        return new Promise((resolve, reject) => {
          let paramsNotice = {
            partnerId: that.$store.state.user.partnerId,
            name: '往期公告',
            page: 1,
            rows: 7
          };
          that.$api.website.getContentColumnName(paramsNotice)
            .then( datas => {
              that.issueList = datas.data;
              this.options = {
                text: datas.data[0].title,
                icon:'', // 左侧图标(不需要icon不传)
                iconSize: '16px', // icon大小（正方形默认16px）
                size: '16px', // 通知内容文字大小（默认16px）
                color: '#B3B3B3', // 通知内容文字颜色(默认#f60)
                backround: '#fff', //背景颜色(默认#fff7cc)
                delay: '1000', // 动画延迟时间(默认一秒后开始滚动，单位毫秒)
                speed: '50' // 滚动速率默认50 (px/s)
              }
              resolve()
            }).catch( err => {
               reject(err)
            });
        })
      },
      getRecommendColumnName() {
        const that = this;
        return new Promise((resolve, reject) => {
          let newsParams = {
            partnerId: that.$store.state.user.partnerId,
            name: '新闻资讯',
            rows: 6
          };
          that.$api.website.getRecommendColumnName(newsParams)
            .then( datas => {
              if (datas.length > 0) {
                let fmt = 'yyyy/MM'
                  , fd = 'dd';
                datas.forEach( data => {
                  let list = data.website_content;
                  list['publish_day'] = util.formatDate(list.publish_time, fd);
                  list.publish_time = util.formatDate(list.publish_time, fmt);
                  that.newsList.push(list)
                });
              }
            });

          let policiesParams = {
            partnerId: that.$store.state.user.partnerId,
            name: '政策法规',
            rows: 6
          };
          that.$api.website.getRecommendColumnName(policiesParams)
            .then( datas => {
              if (datas.length > 0) {
                let fmt = 'yyyy-MM-dd';
                datas.forEach( item  => {
                  let list = item.website_content;
                  list.publish_time = util.formatDate(list.publish_time, fmt);
                  that.policiesList.push(list)
                })
              }
              resolve()
            }).catch( err => {
              reject(err)
          })
        })
      },
      getRecommendRecomHall() {
        const that = this;
        return new Promise((resolve,reject) => {
          let params = {
            page: 1,
            rows: 6
          };
          that.$api.website.getRecommendRecomHall(params)
            .then( res => {
              that.hallList = res.data;
              resolve()
              this.$nextTick(()=>{
                this.initHallSwiper();
              })
            }).catch( err => {
              reject()
          })
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .demo{
    display: inline-block;
    width: 800px;
    height: 450px;
    text-align: center;
    line-height: 100px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  }

  .demo:hover{
    display: block;
  }

  #hall-banner {
    overflow: visible !important;
    /deep/ .swiper-pagination-bullets {
      bottom: -50px;
    }
  }
  #home-banner {
    width: 100%;
    .swiper-slide {
      position: relative;
      min-height: 284px;
      img {
        object-fit: cover;
      }
      .auto-plpay {
        position: absolute;
        z-index: 999;
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,.2);
        cursor: pointer;
        .auto-plpay-txt {
          display: flex;
          flex-direction: column;
          i {
            font-size: 60px;
            color: #ffffff;
          }
          span {
            font-size: 18px;
            color: #ffffff;
            text-shadow: #000 3px 3px 3px;
          }
        }
      }
      video {
        width: 100%;
      }
      img {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }
    }
  }
  #news-banner {
     /deep/ .swiper-pagination-bullets {
       display: flex;
       align-items: center;
       justify-content: flex-end;
       bottom: 10px;
     }
    /deep/ .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 100%;
      background: #fff;
      opacity: 1;
    }
    /deep/ .swiper-pagination-bullet-active {
      opacity: 1;
      background: #007aff;
    }
  }
  @import "./index.scss";
</style>
